import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"


import LuteinComplexMobile from "./../images/about-mobile.jpg"
import LuteinComplexTablet from "./../images/about-tablet.jpg"
import LuteinComplexDesktop from "./../images/about-desktop.jpg"
import LuteinComplexXL from "./../images/about-desktop-xl.jpg"

const ContactPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "contact" }}>
    <Seo title="Свържете се с нас" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <Breadcrumb.Item active>КОНТАКТИ</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="About Vita Herb"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-5 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Контакти
          </h1>
        </Col>
        <Col sm={12} className="order-md-0">
          <p>
            София 1113, <br />
            Бул. Шипченски проход 18, <br />
            ТЦ Галакси, ниво -1, магазин 007 <br />
            Регистрирано в ТР на АВп при МП с ЕИК: 175150004
          </p>

          <ul className="list-unstyled">
            <li>Тел: (02) 944 26 27</li>
            <li>Тел: 0876 18 1000</li>
            <li>e-mail: info@vitaherb.bg</li>
          </ul>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContactPage
